// App
// ----------------------
import React, { 
  //useState 
} from 'react';
// routing
import { Routes, Route, } from "react-router-dom";
import { NoMatch } from './router-no-match.js'; // 404 - No Match
import { PrivateRouteAuthMsal, } from './routes'; // custom routes
import { HelmetProvider } from 'react-helmet-async';
// authentication
// auth/msal
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './features/auth/msal/authConfig';
//import AuthMsalLogin from './features/auth/msal/login/login';
// Public
import PublicDefaultLayout from './layouts/public/default-layout/default-layout'; // Default Layout
// components
import Homepage from './features/public/homepage/homepage'; // public marketing homepage
import LoginBase from './features/portal/login/base/login-base'; // login
import ExternalSession from './features/campaign-monitor/external-session/external-session'; // external-session
import PrivateDashboardHome from './components/private/dashboard-home/dashboard-home'; // dashboard-home

// libraries
import { ToastContainer } from 'react-toastify';
import 'semantic-ui-less/semantic.less';
import 'react-toastify/dist/ReactToastify.min.css';
// styles
import './App.css'; //App level css import
import './App.scss'; //App level scss import
//import stylesCss from './App-css-module.module.css'; //App level-scoped css import
//import stylesScss from './App-scss-module.module.scss'; //App level-scoped scss import

// auth/msal config
export const msalInstance = new PublicClientApplication(msalConfig);
// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
//console.debug('main.js - accounts', accounts);
if (accounts.length > 0) {
  //console.debug('main.js - accounts - setActiveAccount', accounts[0]);
  msalInstance.setActiveAccount(accounts[0]);
}
msalInstance.addEventCallback((event) => {
  //console.debug('main.js - msalInstance - EventCallback - event', event);
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    //console.debug('main.js - msalInstance - EventCallback - setActiveAccount', event.payload.account);
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
  if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && event.payload.accessToken) {
    //console.debug('main.js - msalInstance - EventCallback - ACQUIRE_TOKEN_SUCCESS', event.payload.accessToken);
    const accessToken = event.payload.accessToken;
    //update localstorage with new accessToken
    if (typeof localStorage !== 'undefined') {
      const localStorage_msGraphAuth = JSON.parse(localStorage.getItem('msGraphAuth'));
      //console.debug('main.js - msalInstance - EventCallback - ACQUIRE_TOKEN_SUCCESS - localStorage_msGraphAuth', localStorage_msGraphAuth);
      if (localStorage_msGraphAuth !== null && (localStorage_msGraphAuth.accessToken !== accessToken)) {
        //console.debug('main.js - msalInstance - EventCallback - ACQUIRE_TOKEN_SUCCESS - localStorage_msGraphAuth does not match accessToken - update local storage');
        //console.debug('main.js - msalInstance - EventCallback - ACQUIRE_TOKEN_SUCCESS - localStorage access token: '+localStorage_msGraphAuth.accessToken);
        //console.debug('main.js - msalInstance - EventCallback - ACQUIRE_TOKEN_SUCCESS - new access token: '+accessToken);
        localStorage_msGraphAuth.accessToken = accessToken;
        localStorage.setItem('msGraphAuth', JSON.stringify(localStorage_msGraphAuth));
        // need to reload to update the WS connection headers
        //console.debug('main.js - should reload here to update WS connection with localstorage');
        //window.location.reload();
      }
    }
  }
});

// examples below show different ways to use the Private/Protected Routes
function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <Routes>
          {/* public homepage */}
          <Route path={'/'} element={<PrivateRouteAuthMsal instance={msalInstance} redirectTo={'/login'} component={Homepage} />} />
          {/* public login */}
          <Route path={'/login'} element={<PublicDefaultLayout />}>
            <Route index element={<LoginBase instance={msalInstance} />} />
          </Route>
          {/* private dashboard */}
          <Route path={'/dashboard'} element={<PrivateRouteAuthMsal instance={msalInstance} redirectTo={'/login'} />}>
            <Route index element={<PrivateDashboardHome />} />
            <Route path={'campaign-monitor'} element={<ExternalSession />} />
          </Route>
          <Route path="*" element={<NoMatch />} />
        </Routes>
        <ToastContainer />
      </div>
    </HelmetProvider>
  );
}

// function PortalCreate() {
//   return <div className="content">Welcome PortalCreate page.</div>
// }

/* function Dashboard() {
  return <div className="content">Welcome Dashboard page.</div>
} */

export default App;
