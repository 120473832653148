import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit'

// check local storage for data
let initialState
let data_tests
if (typeof localStorage === 'undefined') {
  data_tests = null
  initialState = null
} else {
  data_tests = {
    cm_SessionUrl : localStorage.getItem('cm_SessionUrl')
  }

  initialState = data_tests.cm_SessionUrl ? {
    value: 0,
    SessionUrl: data_tests.cm_SessionUrl,
    cmLoginUrl: {
      loading: false,
      error: null,
      collection: null,
    }
  } : {
    value: 0,
    SessionUrl: null,
    cmLoginUrl: {
      loading: false,
      error: null,
      collection: null,
    }
  };
}

const cmApiEndpoints = {
  base: '/api/',
  externalSession: 'external-session'
}

/* const initialState = {
  value: 0,
  SessionUrl: null,
  cmLoginUrl: {
    loading: false,
    error: null,
    collection: null,
  }
} */

// get cm login url
export const getCmLoginUrl = createAsyncThunk(
  'cm/getCmLoginUrl',
  async (args, { getState, rejectWithValue, }) => {
    //console.debug('getCmLoginUrl - args', args);
    const headers = {
      'EMAILADDRESS': args.email,
    }
    try {
      const res = await fetch(cmApiEndpoints.base+''+cmApiEndpoints.externalSession, { 
        method: 'GET',
        headers: headers,
        //body: JSON.stringify({requestData}), 
      })
        .then((data) => data.json())
        .then((data) => {
          //console.debug('getCmLoginUrl - res - data', data);
          if (data.error) {
            //console.debug('getCmLoginUrl - res - data.error', data.error);
            throw data.error
          }
          if (data && data.SessionUrl) {
            localStorage.setItem('cm_SessionUrl', data.SessionUrl);
          }
          return data
        })
      return res
    } catch (err) {
      //console.debug('getCmLoginUrl - err', err);
      return rejectWithValue(err.api_response.data)
    }
})

export const campaignMonitorSlice = createSlice({
  name: 'campaignMonitor',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
    deleteCmLoginUrl: (state, action) => {
      localStorage.removeItem('cm_SessionUrl')
      state.SessionUrl = null
    },
  },
  extraReducers: {
    [getCmLoginUrl.pending]: (state) => {
      state.cmLoginUrl.loading = true
      state.cmLoginUrl.error = false
    },
    [getCmLoginUrl.fulfilled]: (state, { payload }) => {
      state.cmLoginUrl.loading = false
      state.cmLoginUrl.collection = payload
      state.SessionUrl = payload.SessionUrl ? payload.SessionUrl : null
      state.cmLoginUrl.error = false
    },
    [getCmLoginUrl.rejected]: (state, { payload }) => {
      state.cmLoginUrl.loading = false
      state.cmLoginUrl.collection = null
      state.cmLoginUrl.error = payload ? payload : true
    },
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount, deleteCmLoginUrl } = campaignMonitorSlice.actions
export const selectCampaignMonitor = (state) => state.campaignMonitor;
export const campaignMonitorReducer = campaignMonitorSlice.reducer
//export default campaignMonitorSlice.reducer