/*
    Portal - Internal - Login Page
*/

// React
import React, { useEffect } from 'react';
// Routing via React Router
import { Navigate } from 'react-router-dom'
// HOC/decorator to listen to Redux store state
import { useDispatch, useSelector } from 'react-redux';
// MSAL imports
import { loginRequest } from '../../../auth/msal/authConfig';
import { selectAuthMsal, fetchMSGraphUserIfNeeded, requestMSGraphUserLogout } from '../../../auth/msal/authSlice';
import { MsalProvider, useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
// campaign monitor api
import { selectCampaignMonitor, getCmLoginUrl } from '../../../campaign-monitor/campaignMonitorSlice';

// Semantic UI
import {
  Dimmer,
  Loader,
  Button,
  Card,
  Image,
  Header,
  Message,
  Label,
  Container,
  Segment,
} from 'semantic-ui-react';

import Avatar from 'react-avatar';

// Styles
import styles from './login-internal.module.scss';

// SVG Images
// icons
//import svg_icon_replay from 'static/vector/icons/replay.svg';

export const LoginInternal = (props) => {
  const { instance, } = props;
  //const dispatch = useDispatch();
  // handle user login
  const handle_msalLogin = () => {
    //console.debug('handle_msalLogin - instance', JSON.stringify(instance));
    //console.debug('handle_msalLogin - loginRequest.scopes', loginRequest.scopes);
    instance.loginRedirect({
      scopes: loginRequest.scopes
    });
  }

  return (
    <div className={styles.login_internal}>
      <Container>
        <Card>
          <Card.Content textAlign={'center'} className={'card-main-content'}>
            <MsalProvider instance={instance}>
              <AuthenticatedTemplate>
                <ProfileContent />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <React.Fragment>
                  <Card.Header>Login iHeartMedia Account</Card.Header>
                  <Card.Description>
                    Access the iHeartMedia Email Marketing Dashboard by logging in with your employee account.
                    <Message warning header='Not Authenticated' content='Authentication required.' />
                  </Card.Description>
                  <Card.Content extra>
                    <div className={'action-options'}>
                      <Button primary content={'Login'} onClick={()=>handle_msalLogin()} />
                    </div>
                  </Card.Content>
                </React.Fragment>
              </UnauthenticatedTemplate>
            </MsalProvider>
          </Card.Content>
        </Card>
      </Container>
    </div>
  )
}

const ProfileContent = () => {
  const dispatch = useDispatch()
  const { instance, inProgress } = useMsal();
  const msalAccount = useSelector(selectAuthMsal);

  const campaignMonitor = useSelector(selectCampaignMonitor);

  useEffect(() => {
    dispatch(fetchMSGraphUserIfNeeded())
  }, [dispatch,])

  const handle_msalLogout = () => {
    //console.debug('handle_msalLogout');
    dispatch(requestMSGraphUserLogout(instance));
    //instance.logout();
  }

  const handle_portalLogin = () => {
    //console.debug('handle_portalLogin');
    dispatch(getCmLoginUrl({ email: msalAccount.graphProfile.mail }));
  }

  return (
    <React.Fragment>
      {!msalAccount.loading && msalAccount.error === null && msalAccount.graphProfile !== null ?
        <React.Fragment>
          <Card.Header>Welcome</Card.Header>
          <Card.Description>
            Access the Campaign Monitor Dashboard.
            <Message success header='Authenticated' content='If your identity does not match the logged in user below, please log out and re-login.' />
          </Card.Description>
          <Card.Description style={{marginTop:'25px', marginBottom: '25px',}}>
            <Segment className={'avatar-wrapper'}>
              <Header as='h2' textAlign={'left'} className={'avatar'}>
                {typeof msalAccount.graphProfile.photo !== 'undefined' ?
                  <Image src={msalAccount.graphProfile.photo} circular style={{width: '3em',}} />
                : 
                  <Avatar size={80} name={msalAccount.graphProfile.displayName} style={{ display: 'inline-block', marginRight:'15px', }} />
                }
                <Header.Content>
                  {msalAccount.graphProfile.displayName}
                  <Header.Subheader>{msalAccount.graphProfile.mail}</Header.Subheader>
                  <Header.Subheader style={{marginTop: '8px'}}>
                    <Label size={'tiny'}>{msalAccount.graphProfile.officeLocation}</Label>
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </Segment>
          </Card.Description>

          {!campaignMonitor.cmLoginUrl.loading && !campaignMonitor.cmLoginUrl.error && campaignMonitor.cmLoginUrl.collection === null ?
            <React.Fragment>
              <Card.Content extra>
                <div className={'action-options spaced'}>
                  <Button primary content={'Launch Dashboard'} onClick={()=>{handle_portalLogin()}} />
                  <Button secondary content={'Log Out'} onClick={()=>handle_msalLogout()} disabled={inProgress === 'logout'} />
                </div>
              </Card.Content>
            </React.Fragment>
          : !campaignMonitor.cmLoginUrl.loading && !campaignMonitor.cmLoginUrl.error && campaignMonitor.cmLoginUrl.collection && campaignMonitor.cmLoginUrl.collection.SessionUrl ?
            <React.Fragment>
              {/* <Card.Header>You've successfully logged in</Card.Header>
              <Card.Content extra>
                <div className={'action-options spaced'}>
                  <Button as={Link} primary to={'/dashboard'}>
                    Access Dashboard
                  </Button>
                  <Button secondary content={'Log Out'} onClick={()=>handle_msalLogout()} disabled={inProgress === 'logout'} />
                </div>
              </Card.Content> */}
              <Navigate to={'/dashboard'} />
            </React.Fragment>
          : !campaignMonitor.cmLoginUrl.loading && campaignMonitor.cmLoginUrl.error ?
            <React.Fragment>
              {campaignMonitor.cmLoginUrl.error && campaignMonitor.cmLoginUrl.error.Code && campaignMonitor.cmLoginUrl.error.Message ?
                <Message error 
                  header={'Campaign Monitor Error '+ campaignMonitor.cmLoginUrl.error.Code} 
                  content={campaignMonitor.cmLoginUrl.error.Message} 
                />
              :
                <Message error header={'Campaign Monitor Error'} content={'An error occurred.'} />
              }
              <Card.Content extra>
                <div className={'action-options'}>
                  <Button secondary content={'Log Out'} onClick={()=>handle_msalLogout()} disabled={inProgress === 'logout'} />
                </div>
              </Card.Content>
            </React.Fragment>
          :
            <Dimmer active inverted><Loader content='Logging In Campaign Monitor...' /></Dimmer>
          }
        </React.Fragment>
      : msalAccount.loading ?
        <React.Fragment>
          <Card.Header>Login iHeartMedia Account</Card.Header>
          <Loader active inline='centered' content='Logging In...' />
        </React.Fragment>
      : msalAccount.error !== null ?
        <React.Fragment>
          <p>An error occurred.</p>
          <pre>{msalAccount.error}</pre>
        </React.Fragment>
      :
        null
      }
    </React.Fragment>
  )
}

export default LoginInternal