// Podcast Networks - Footer

// ----------------------

// React
import React, { useState, } from 'react';

// Routing via React Router
//import { Link } from 'react-router-dom';

// load semantic-ui components needed for Marketing Landing page layout
import {
  Segment,
  Container,
  Image,
  List,
  Grid,
  //Header,
} from 'semantic-ui-react';

// NPM
import dayjs from 'dayjs';

// styles
import styles from './footer.module.scss';

// iHeart Logo - Menu
//import svg_iHR_Logo_FullColor from '../../../images/iheartradio-logo-fullcolor.svg'
import image_logo_ihm_horzColor from '../../../../app/assets/images/ihm-logo-horz-color.png'

// ----------------------

export default function Footer () {
  const [currentYear] = useState(dayjs().year());

  return (
    <Segment id="footer" basic vertical style={{ padding: '5em 0em' }} className={styles.footer}>
      <Container textAlign='center'>
        <Grid doubling stackable columns={'equal'} className={'footer-content'}>
          <Grid.Row>
            <Grid.Column computer={5} mobile={16} textAlign={'left'} className={'info-column'}>
              <Image wrapped size={'medium'} src={image_logo_ihm_horzColor} className={'logo-image'} />
              {/* <p> Music, radio and podcasts, all free. Listen online or download the iHeart App.</p> */}
              {/* <div className={'connect-row'}>
                <Header as='h4' content='CONNECT' />
                <List link horizontal size={'small'}>
                  <List.Item as={'a'} href={'https://www.facebook.com/iheartradio'} target={'_blank'}>
                    <List.Icon size={'large'} name='facebook' />
                  </List.Item>
                  <List.Item as={'a'} href={'https://twitter.com/iHeartRadio'} target={'_blank'}>
                    <List.Icon size={'large'} name='twitter' />
                  </List.Item>
                  <List.Item as={'a'} href={'https://instagram.com/iHeartRadio'} target={'_blank'}>
                    <List.Icon size={'large'} name='instagram' />
                  </List.Item>
                  <List.Item as={'a'} href={'https://www.youtube.com/user/iHeartRadio'} target={'_blank'}>
                    <List.Icon size={'large'} name='youtube' />
                  </List.Item>
                </List>
              </div> */}
            </Grid.Column>
            {/* <Grid.Column textAlign={'left'}>
              <Header as='h4' content='EXPLORE' />
              <List link relaxed>
                <List.Item as='a' href={'https://www.iheart.com/'} target={'_blank'}>iHeart</List.Item>
                <List.Item as='a' href={'https://www.iheart.com/live/'} target={'_blank'}>Live Radio</List.Item>
                <List.Item as='a' href={'https://www.iheart.com/podcast/'} target={'_blank'}>Podcasts</List.Item>
                <List.Item as='a' href={'https://www.iheart.com/artist/'}  target={'_blank'}>Artist Radio</List.Item>
                <List.Item as='a' href={'https://www.iheart.com/playlist/'}  target={'_blank'}>Playlists</List.Item>
                <List.Item as='a' href={'https://www.iheart.com/genre/'}  target={'_blank'}>Genres</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column textAlign={'left'}>
              <Header as='h4' content='EXCLUSIVES' />
              <List link>
                <List.Item as='a' href={'https://www.iheart.com/news/'}  target={'_blank'}>News</List.Item>
                <List.Item as='a' href={'https://www.iheart.com/content/iheartradio-features/'}  target={'_blank'}>Features</List.Item>
                <List.Item as='a' href={'https://www.iheart.com/content/iheartradio-events/'}  target={'_blank'}>Events</List.Item>
                <List.Item as='a' href={'https://news.iheart.com/contests/'}  target={'_blank'}>Contests</List.Item>
                <List.Item as='a' href={'https://news.iheart.com/photo/'}  target={'_blank'}>Photos</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column textAlign={'left'}>
              <Header as='h4' content='INFORMATION' />
              <List link>
                <List.Item as='a' href={'https://www.iheart.com/about/'}  target={'_blank'}>About</List.Item>
                <List.Item as='a' href={'https://www.iheartmedia.com/advertising'}  target={'_blank'}>Advertise</List.Item>
                <List.Item as='a' href={'https://blog.iheart.com/'}  target={'_blank'}>Blog</List.Item>
                <List.Item as='a' href={'https://brand.iheart.com/'}  target={'_blank'}>Brand Guidelines</List.Item>
                <List.Item as='a' href={'https://www.iheart.com/content/general-contesting-guidelines/'}  target={'_blank'}>Contest Guidelines</List.Item>
                <List.Item as='a' href={'https://www.iheart.com/offers/'}  target={'_blank'}>Subscription Offers</List.Item>
                <List.Item as='a' href={'https://jobs.iheart.com/'}  target={'_blank'}>Jobs</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column textAlign={'left'}>
              <Header as='h4' content='GET THE APP' />
              <List link>
                <List.Item as='a' href={'https://www.iheart.com/apps/#auto'}  target={'_blank'}>Automotive</List.Item>
                <List.Item as='a' href={'https://www.iheart.com/apps/#home'}  target={'_blank'}>Home</List.Item>
                <List.Item as='a' href={'https://marketing.pages.iheart.com/text-to-download/index.html?sc=homepage&pname=national&cid=programming&keyid=text_to_download'}  target={'_blank'}>Mobile</List.Item>
                <List.Item as='a' href={'https://www.iheart.com/apps/#wear'}  target={'_blank'}>Wearables</List.Item>
              </List>
            </Grid.Column> */}
          </Grid.Row>
        </Grid>
        <Grid columns={'equal'} stackable className={'footer-extra-content'}>
          <Grid.Row>
            <Grid.Column width={5} textAlign={'left'} className={'copyright-column'}>
              <p>&copy; {currentYear} iHeartMedia, Inc.</p>
            </Grid.Column>
            <Grid.Column textAlign={'right'} className={'more-info-column'}>
              <List horizontal divided link relaxed size={'small'}>
                <List.Item as='a' href='https://www.iheart.com/help/' target={'_blank'}>
                  Help
                </List.Item>
                <List.Item as='a' href='https://www.iheart.com/privacy/' target={'_blank'}>
                  Privacy Policy
                </List.Item>
                {/* <List.Item as='a' href='https://iheart.com/optout/' target={'_blank'}>
                  Do Not Sell My Personal Information
                </List.Item> */}
                <List.Item as='a' href='https://www.iheart.com/terms/' target={'_blank'}>
                  Terms of Use
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>            
      </Container>
    </Segment>
  )
}
