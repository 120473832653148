// Layout - PrivateRoute - Dashboard

// ----------------------
// IMPORTS

// React
import React, { useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ProSidebar,
  Menu as ProSidebarMenu,
  MenuItem,
  //SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';

// auth/ihr-web-sdk
//import { selectAuthIhrWebSdk } from '../../../../features/auth/ihr-web-sdk/authSlice';
// auth/msal
import { useMsal, } from "@azure/msal-react";
import { selectAuthMsal, requestMSGraphUserLogout, } from '../../../../features/auth/msal/authSlice';
// campaign monitor 
import { selectCampaignMonitor, deleteCmLoginUrl } from '../../../../features/campaign-monitor/campaignMonitorSlice';

/* // Router Customizations for minimum features of Nav UI/UX: - Smooth scroll - Scroll Spy
// Add support for hashlinks and smooth scrolling, replaces Link and NavLink above in react-router-dom import because theyre being modified in react-router-hash-link
import { HashLink as Link, NavHashLink as NavLink } from 'react-router-hash-link'; */
import { Outlet, Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

// NPM
// Semantic UI
import {
  //Container,
  Segment,
  Header,
  Image,
  //Menu,
  Button,
  Icon,
  Divider,
  //Label,
  Popup,
} from 'semantic-ui-react';
import Avatar from 'react-avatar';

// import styles
import styles from './dashboard-layout.module.scss';
import './sidebar.scss';

// import assets
import image_logo from '../../../../app/assets/images/ihm-cm-ad-logo-white.png';
// iHeart Logo - Menu
//import Primary_iHeart_Logo_White from '../../../images/iheartradio-logo-white.svg';
import { generateSvgiHeartLogo } from '../../../utils';

// ----------------------

export default function PrivateDashboardLayout (props) {
  //console.debug('props', props);
  const { children, component: Component } = props;
  const dispatch = useDispatch()
  const { instance, inProgress } = useMsal();
  const user = useSelector(selectAuthMsal);
  //console.debug('user', user);
  const campaignMonitor = useSelector(selectCampaignMonitor);

  const [collapsed, setCollapsed] = useState(false);
  const [toggledSidebar, setToggledSidebar] = useState(false);

  const handleCollapsedChange = () => {
    //console.debug('handleCollapsedChange', collapsed);
    setCollapsed(!collapsed);
  };
  const handleToggleSidebar = (value) => {
    setToggledSidebar(value);
  };
  const handle_userLogout = () => {
    //console.debug('handle_userLogout');
    dispatch(deleteCmLoginUrl());
    dispatch(requestMSGraphUserLogout(instance));
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>iHeartMedia Email Marketing - Dashboard</title>
      </Helmet>
      <div className={styles.layout_dashboard}>
        <ProSidebar width={'260px'} breakPoint={'xs'} image={false} collapsed={collapsed} toggled={toggledSidebar} onToggle={handleToggleSidebar}>
          <SidebarHeader>
            <Image fluid src={image_logo} className={'Layer_2'} />
            {generateSvgiHeartLogo('white')}
            <Header as={'h4'} inverted textAlign='center' style={{ margin: '8px 0 0 0', padding: '0', }}>iHeartMedia Email Marketing</Header>
            <Popup basic inverted content={collapsed ? 'Expand' : 'Collapse'} position='right center'
              //disabled={!collapsed}
              trigger={
                <Button icon basic inverted size={'mini'} onClick={()=>handleCollapsedChange()}
                  //labelPosition={collapsed ? null : 'right'}
                >
                  <Icon name='exchange' /> 
                  {/* {collapsed ? null : 'Collapse'} */}
                </Button>
              } 
            />
            <Divider inverted />
            {!user.loading && user.error === null && user.graphProfile !== null ?
              <Popup basic inverted content='Profile' position='right center'
                disabled={!collapsed}
                trigger={
                  <Segment basic textAlign='center' className={'mini-profile'} style={{ padding: '0', }}>
                    <Header inverted
                      //as={Link}
                      //to={'/dashboard/profile'}
                    >
                      {typeof user.graphProfile.photo !== 'undefined' ?
                        <Image circular src={user.graphProfile.photo} />
                      :
                        <Avatar size={36} name={user.graphProfile.displayName} style={{ display: 'inline-block', marginRight: '8px', fontSize: '1rem', }} />
                      }
                      <Header.Content style={{ fontSize: '1rem', textAlign: 'left', }}>
                        {user.graphProfile.displayName}
                        <Header.Subheader style={{ fontSize: '11px', }}>{user.graphProfile.officeLocation}</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Segment>
                } 
              />
            :
              null
            }
          </SidebarHeader>
          <SidebarContent>
            <ProSidebarMenu iconShape="circle">
              <Popup basic inverted content='Home' position='right center'
                disabled={!collapsed}
                trigger={
                  <MenuItem icon={<Icon name={'home'} />} 
                    //suffix={<Label color={'red'} content={'New'} />}
                  >
                    Home
                    <Link to={'/dashboard'} />
                  </MenuItem>
                } 
              />
              <Popup basic inverted content='Campaign Monitor' position='right center'
                disabled={!collapsed || (campaignMonitor && !campaignMonitor.SessionUrl)}
                trigger={
                  <MenuItem icon={<Icon name={'send'} />}>
                    Campaign Monitor
                    <Link to={'/dashboard/campaign-monitor'} />
                  </MenuItem>
                } 
              />
            </ProSidebarMenu>
          </SidebarContent>
          <SidebarFooter style={{ textAlign: 'center' }}>
            <Popup basic inverted content='Logout' position='right center'
              disabled={!collapsed}
              trigger={
                <Button fluid negative icon labelPosition={collapsed ? null : 'left'} style={{ marginTop: '5px', }} onClick={()=>handle_userLogout()} disabled={inProgress === 'logout'}>
                  <Icon name='sign-out' /> {collapsed ? null : 'Logout'}
                </Button>
              } 
            />
            <Divider inverted />
            <Popup basic inverted content='Help & Support' position='right center'
              disabled={!collapsed}
              trigger={
                <Button as={'a'} size={'small'} icon labelPosition={collapsed ? null : 'left'} style={{ marginTop: '5px', }}
                  href={'https://iheartmedia.sharepoint.com/teams/EmailMarketing/SitePages/Campaign-Monitor-Support-Requests.aspx'}
                  target={'_blank'}
                >
                  <Icon name='question' /> {collapsed ? null : 'Help & Support'}
                </Button>
              } 
            />
            
          </SidebarFooter>
        </ProSidebar>
        <main>
          {/* <Menu id='main-navbar' fixed={'top'} size={'large'} inverted pointing secondary borderless style={{ position: 'relative', borderTop: 'none' }}>
            <Container fluid>
              <Menu.Item position='left' className='navbar-menu-button' onClick={()=>handleCollapsedChange()}>
                <div>
                  <Icon name='sidebar' size='large' />
                </div>
              </Menu.Item>
            </Container>
          </Menu> */}
          <div id={'main-content-container'}>
            {children ? 
              children 
            : Component ? 
              <Component />
            :
              <Outlet />
            }
          </div>
        </main>
      </div>
    </React.Fragment>
  )
}
