// Theme Selector

// ----------------------
// IMPORTS

/* NPM */

// React
import React, { Suspense } from 'react';
//import ThemeiHmCmSso from './themes/ihm-cm-sso/';

const ThemeiHmCmSso = React.lazy(() => import('./themes/ihm-cm-sso'));

// FROM ENV VARS
const REACT_APP_SEMANTIC_THEME = process.env.REACT_APP_SEMANTIC_THEME;

// ----------------------

//const Loading = () => <div>loading...</div>;

// theme selector
const ThemeSelector = ({ children }) => {
  //console.debug('ThemeSelector - REACT_APP_SEMANTIC_THEME', REACT_APP_SEMANTIC_THEME);
  return (
    <React.Fragment>
      <Suspense fallback={<div>Loading...</div>}>
        {REACT_APP_SEMANTIC_THEME === 'ihm-cm-sso' && <ThemeiHmCmSso />}
      </Suspense>
      {children}
    </React.Fragment>
  )
};

/* const ThemeSelector = ({ children }) => {
  return (
    <React.Fragment>
      {REACT_APP_SEMANTIC_THEME === 'ihm-cm-sso' && <ThemeiHmCmSso />}
      {children}
    </React.Fragment>
  )
} */

export default ThemeSelector;