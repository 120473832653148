import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  counter: {
    value: 0,
  }
}

export const publicSlice = createSlice({
  name: 'public',
  initialState,
  reducers: {
    counter_increment: (state) => {
      state.counter.value += 1
    },
    counter_decrement: (state) => {
      state.counter.value -= 1
    },
    counter_incrementByAmount: (state, action) => {
      state.counter.value += action.payload
    },
  },
})

export const { counter_increment, counter_decrement, counter_incrementByAmount } = publicSlice.actions
export const selectPublic = (state) => state.public;
export const publicReducer = publicSlice.reducer
