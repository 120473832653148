import { LogLevel } from "@azure/msal-browser";

// FROM ENV VARS
const PUBLIC_URL = process.env.PUBLIC_URL;
//console.log('index.js - PUBLIC_URL', PUBLIC_URL);

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
        authority: "https://login.microsoftonline.com/Iheartmedia.onmicrosoft.com",
        redirectUri: typeof window !== 'undefined' ? window.location.origin + PUBLIC_URL +'/login' : null,
        postLogoutRedirectUri: typeof window !== 'undefined' ? window.location.origin + PUBLIC_URL +'/login' : null,
    },
    cache: {
      //cacheLocation: 'localStorage',
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {	
                    return;	
                }
                switch (level) {	
                    case LogLevel.Error:	
                        console.error(message);	
                        return;	
                    case LogLevel.Info:	
                        //console.info(message);	
                        return;	
                    case LogLevel.Verbose:	
                        console.debug(message);	
                        return;	
                    case LogLevel.Warning:	
                        console.warn(message);	
                        return;	
                    default:
                        return;
                }
            }
        }
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["User.Read", "openid", "profile", "offline_access"]
};

// Add scopes here for access token to be used at Microsoft Graph API endpoints.
// const tokenRequest = {
//     scopes: ["User.Read", "openid", "profile", "offline_access"]
// };

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
    graphMePhotoEndpoint: "https://graph.microsoft.com/beta/me/photo/$value"
};