import { createSlice } from '@reduxjs/toolkit';
// MSAL imports
import { EventType, AuthError } from "@azure/msal-browser";
// MS Graph Service
import { callMsGraph } from './utils/MsGraphApiCall';

// check local storage for auth credentials
let initialState
let auth_tests
if (typeof localStorage === 'undefined') {
  //console.debug('you should probably be in a real browser for this');
  auth_tests = null
  initialState = null
} else {
  auth_tests = {
    dashboardUser : JSON.parse(localStorage.getItem('dashboard_user'))
  }

  initialState = auth_tests.dashboardUser ? { loading: false, loggedIn: true, graphProfile: auth_tests.dashboardUser, error: null, receivedAt: Date.now(), } : { loading: false, loggedIn: false, graphProfile: null, error: null, receivedAt: null, };
}

/* const initialState = { 
  loading: false,
  isAuthenticated: false,
  user: null,
  token: null,
  loggedIn: false, 
  graphProfile: null, 
  error: null, 
  receivedAt: null,
}; */
export const authMsalSlice = createSlice({
  name: 'authMsal',
  initialState,
  reducers: {
    requestMsGraphUser: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    recieveMsGraphUser: (state, action) => {
      return {
        ...state,
        loading: false,
        loggedIn: true,
        graphProfile: action.payload,
        receivedAt: Date.now(),
      };
    },
    errorMsGraphUser: (state, action) => {
      return {
        ...state,
        loading: false,
        loggedIn: false,
        error: action.payload,
        receivedAt: Date.now(),
      };
    },
    logoutMsGraphUser: () => {
      return initialState;
    },
  },
});

const fetchMSGraphUser = () => dispatch => {
  dispatch(requestMsGraphUser());
  return callMsGraph()
    .then(response => {
      console.debug('fetchMSGraphUser callMsGraph - response', response);
      // set localstorage data for private routes
      localStorage.setItem('dashboard_user', JSON.stringify(response));
      // remove auth dependent localStorage objects
      localStorage.removeItem('cm_SessionUrl')
      dispatch(recieveMsGraphUser(response))
    })
    //.then(response => dispatch(receiveMSGraphUser(response)))
    .catch(error => {
      console.debug('fetchMSGraphUser error', error);
      dispatch(errorMsGraphUser(error));
    })
}
const shouldFetchContent = (state) => {
  //console.log('shouldFetchContent state', state);
  const content = state.authMsal;
  if (content.loading) {
    return false
  }
  if (!content.graphProfile) {
    return true
  }
  return content.didInvalidate
}
export const fetchMSGraphUserIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchContent(getState())) {
    return dispatch(fetchMSGraphUser())
  }
}

export const requestMSGraphUserLogout = (instance) => dispatch => {
  // setup post logout actions
  // eslint-disable-next-line
  const callbackId = instance.addEventCallback((message) => {
    // Update UI or interact with EventMessage here
    if (message.eventType === EventType.LOGOUT_START) {
      console.debug('requestMSGraphUserLogout - EventType.LOGOUT_START', message.payload);
    } else if (message.eventType === EventType.LOGOUT_SUCCESS) {
      console.debug('requestMSGraphUserLogout - EventType.LOGOUT_SUCCESS', message.payload);
      localStorage.removeItem('dashboard_user');
      dispatch(logoutMsGraphUser());
    } else if (message.eventType === EventType.LOGOUT_FAILURE) {
      if (message.error instanceof AuthError) {
        console.debug('requestMSGraphUserLogout - EventType.LOGOUT_FAILURE', message.error);
      }
    }
  });
  return instance.logoutPopup()
}

export const { requestMsGraphUser, recieveMsGraphUser, errorMsGraphUser, logoutMsGraphUser } = authMsalSlice.actions;
export const selectAuthMsal = (state) => state.authMsal;
export const authMsalReducer = authMsalSlice.reducer;
