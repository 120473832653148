import React from 'react';

import {
  Segment,
  Container,
  Header,
} from 'semantic-ui-react'

import styles from './dashboard-home.module.scss';

export default function DashboardHome() {

  return (
    <Segment basic className={styles.dashboardHomeView}>
      <Segment basic textAlign='left'>
        <Container>
          <Header as='h1' content='Welcome' subheader='Access iHeartMedia Email Marketing Apps with the menu.' />
        </Container>
      </Segment>
      {/* <Segment basic>
        <Container>

        </Container>
      </Segment> */}
    </Segment>
  )
}