import React, { 
  //useState, 
  //useRef 
} from 'react';
import { 
  //useDispatch, 
  useSelector 
} from 'react-redux';

// campaign monitor 
import { selectCampaignMonitor } from '../campaignMonitorSlice';

import {
  Segment,
  Container,
  //Header,
  Message,
  //Dimmer,
  //Loader,
} from 'semantic-ui-react'

import styles from './external-session.module.scss';

export default function ExternalSession() {

  //const iframeRef = useRef(null);

  const campaignMonitor = useSelector(selectCampaignMonitor);

  // const [iframeInfo, setIframeInfo] = useState({
  //   loading: true,
  //   error: null,
  // });

  // const iframe_onLoad = () => {
  //   console.debug('iframe_onLoad');
  //   //const iframeTarget = e.target;
  //   //console.debug('iframe_onLoad - iframeRef', iframeRef);
  //   //const isError = iframeTarget.contentDocument && iframeTarget.contentDocument.body ? false : true;
  //   setIframeInfo({
  //     ...iframeInfo,
  //     loading: false,
  //     //error: isError,
  //   })
  // }

  //console.debug('ExternalSession - iframeInfo', iframeInfo);

  return (
    <Segment basic className={styles.externalSessionView}>
      {/* <Segment basic textAlign='left'>
        <Container>
          <Header as='h1' content='External Session' />
        </Container>
      </Segment> */}
      {campaignMonitor && campaignMonitor.SessionUrl ?
        <Segment basic id={'iframe-segment'} 
          //loading={iframeInfo.loading}
        >
          {/* {iframeInfo.error ?
            <Container text>
              <Segment basic>
                <Message error 
                  header={'Campaign Monitor Error'} 
                  content={'An error occurred.'} 
                />
              </Segment>
            </Container>
          :
            null
          } */}
          {/* {iframeInfo.loading ?
            <Dimmer active inverted><Loader content='Loading...' /></Dimmer>
          : iframeInfo.error ?
            <Message error 
              header={'Campaign Monitor Error'} 
              content={'An error occurred.'} 
            />
          :
            null
          } */}
          <Container fluid className={'iframe-container'}>
            <iframe title={'Campaign Monitor Dashboard'} src={campaignMonitor.SessionUrl} width={'100%'} frameBorder={0} 
              //onLoad={()=>{ iframe_onLoad(); }}
              //ref={iframeRef} 
            ></iframe>
          </Container>
        </Segment>
      :
        <Segment basic>
          <Container text>
            <Message error 
              header={'Error'}
              content={'Session Url Not Found'} 
            />
          </Container>
        </Segment>
      }
    </Segment>
  )
}