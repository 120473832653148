
import { Outlet, Navigate, useLocation, 
  //useNavigate 
} from 'react-router-dom'
import { 
  //useDispatch, 
  useSelector 
} from 'react-redux';

import { Loader, Message } from 'semantic-ui-react'

// Public
// Default Layout
import PublicDefaultLayout from './layouts/public/default-layout/default-layout';
// auth/msal
import { MsalProvider, useIsAuthenticated } from "@azure/msal-react";
import { selectAuthMsal } from './features/auth/msal/authSlice';
// Private Dashboard Layout
import PrivateDashboardLayoutMsal from './layouts/private/msal/dashboard-layout/dashboard-layout';

// auth/msal
export const PublicRouteAuthMsal = ({ component: Component, instance, ...rest }) => {
  return (
    <PublicDefaultLayout>
      <Component {...rest} instance={instance} />
    </PublicDefaultLayout>
  )
};
export const PrivateRouteAuthMsal = ({ redirectTo, children, component: Component, instance, ...rest }) => {
  const location = useLocation();
  const { loading, loggedIn, graphProfile, error } = useSelector(selectAuthMsal);
  //console.debug('PrivateRouteAuthMsal - selectAuthMsal', selectAuthMsal);

  return loading ? 
    (
      <Loader content='Logging In...' />
    ) 
  : error ? 
    (
      <Message error header='An Error Occurred' content={error} />
    )
  :
    (
      <MsalProvider instance={instance}>
        <RoutedComponent />
      </MsalProvider>
    )

    function RoutedComponent() {
      const isAuthenticated = useIsAuthenticated();
      //console.debug('PrivateRouteAuthMsal - RoutedComponent - isAuthenticated', isAuthenticated);
  
      if (!isAuthenticated || !loggedIn || graphProfile === null) {
        return (
          <Navigate to={redirectTo} state={{ from: location }} replace />
        );
      } else {
        return (
          <PrivateDashboardLayoutMsal instance={instance}>
            {children ?
              children
            : Component ?
              <Component {...rest} />
            :
              <Outlet />
            }
          </PrivateDashboardLayoutMsal>
        )
      }
    }
};
