/*
    Portal - Login Base
*/

// React
import React, { useState, useEffect } from 'react';

import LoginInternal from '../internal/login-internal';
//import LoginExternal from '../external/login-external';

// Semantic UI
import {
  Transition,
  Segment,
  Image,
  Container,
} from 'semantic-ui-react';

// Styles
import styles from './login-base.module.scss';

// Images
import image_logo from '../../../../app/assets/images/ihm-cm-sso-logo.png';

export default function LoginBase(props) {
  //console.debug('LoginBase - props', props);

  const [sectionVisibility, setSectionVisibility] = useState(false);

  useEffect(() => {
    if (sectionVisibility === false) {
      setSectionVisibility(true);
    }
  }, [sectionVisibility]);
  //console.debug('LoginBase - sectionVisibility', sectionVisibility);

  return (
    <div className={styles.login_base_view}>
      <Segment basic className={'login-base-view'}>
        <Container text>
          <Image centered size={'large'} src={image_logo} />
        </Container>
        <Transition visible={sectionVisibility} animation='fade up' duration={1000}>
          <LoginInternal instance={props.instance} />
        </Transition>
      </Segment>
    </div>
  )
}
