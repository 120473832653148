// Public - Default Layout

// ----------------------
// IMPORTS

// React
import React from 'react';
import Footer from './footer/footer';

// NPM
import { Outlet } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

// get temp site data
//import { SiteData } from './site-data';

// styles
import styles from './default-layout.module.scss';

// images
// iHeart Logo - Menu
//import svg_iHR_Logo_FullColor from '../../images/iheartradio-logo-fullcolor.svg';
//import svg_iHR_Logo_White from '../../images/iheartradio-logo-white.svg';


/* ---------------------- */


export default function PublicDefaultLayout (props) {
  const { children, component: Component } = props;

  return (
    <React.Fragment>
      <Helmet>
        <title>iHeartMedia Email Marketing</title>
      </Helmet>
      <div className={styles.layout_default}>
        <main id={'main-content-container'}>
            {children ? 
              children 
            : Component ? 
              <Component />
            :
              <Outlet />
            }
        </main>
        <Footer />
      </div>
    </React.Fragment>
  )
}
