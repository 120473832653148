export const generateSvgiHeartLogo = (fillColor) => {
  const style = `
    .st0{fill:`+ fillColor +`;}
  `;
  return (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 112.7 83.3" style={{enableBackground: 'new 0 0 112.7 83.3', }}>
    <style>{style}</style>
    <g>
    <g>
    <g>
      <path className="st0" d="M82,0C71.4,0,61.9,5.6,56.3,13.9C50.8,5.6,41.3,0,30.7,0C13.7,0,0,13.7,0,30.7c0,10.8,6.8,18.5,13.9,25.7
        l27.8,26.3c1.5,1.4,3.9,0.3,3.9-1.7V60.5c0-5.9,4.8-10.7,10.7-10.7S67,54.6,67,60.5V81c0,2,2.4,3,3.9,1.7l27.8-26.3
        c7.1-7.2,13.9-14.9,13.9-25.7C112.7,13.7,99,0,82,0z M28.5,54.4c-0.4,0.5-1,0.7-1.6,0.7c-0.5,0-1-0.2-1.4-0.5
        C16.3,46.5,11.7,38,11.7,29.4v-0.1c0-7.4,4.5-15.8,10.5-19.5c1-0.6,2.3-0.3,2.9,0.7s0.3,2.3-0.7,2.9c-4.8,3-8.5,10-8.5,15.9v0.1
        c0,7.3,4.2,14.7,12.4,22C29.2,52.1,29.3,53.5,28.5,54.4z M42.2,46.5c-0.4,0.7-1.1,1.1-1.9,1.1c-0.4,0-0.7-0.1-1-0.3
        c-6.2-3.5-10.4-10.3-10.4-17v-0.1c0-6.4,3.5-12.2,9.2-15.2c1-0.5,2.3-0.1,2.9,0.9c0.5,1,0.1,2.3-0.9,2.9
        c-4.3,2.2-6.9,6.6-6.9,11.4c0,5.2,3.3,10.6,8.2,13.3C42.4,44.2,42.7,45.4,42.2,46.5z M56.3,41c-5.1,0-9.2-4.1-9.2-9.2
        s4.1-9.2,9.2-9.2s9.2,4.1,9.2,9.2C65.6,36.9,61.4,41,56.3,41z M73.4,47.3c-0.3,0.2-0.7,0.3-1,0.3c-0.7,0-1.5-0.4-1.9-1.1
        c-0.6-1-0.2-2.3,0.8-2.9c4.9-2.8,8.2-8.1,8.2-13.3c0-4.8-2.7-9.2-6.9-11.4c-1-0.5-1.4-1.8-0.9-2.9c0.5-1,1.8-1.4,2.9-0.9
        c5.7,3,9.2,8.8,9.2,15.2v0.1C83.8,37,79.6,43.8,73.4,47.3z M101,29.3c0,8.6-4.6,17.1-13.8,25.2c-0.4,0.4-0.9,0.5-1.4,0.5
        c-0.6,0-1.2-0.2-1.6-0.7c-0.8-0.9-0.7-2.2,0.2-3c8.2-7.3,12.4-14.7,12.4-22v-0.1c0-6-3.7-13-8.5-15.9c-1-0.6-1.3-1.9-0.7-2.9
        c0.6-1,1.9-1.3,2.9-0.7C96.5,13.5,101,21.9,101,29.3L101,29.3z"/>
    </g>
    </g>
    </g>
  </svg>
)};