import { loginRequest, graphConfig } from "../authConfig";
import { msalInstance } from "../../../../App";

//blob util
const blobUtil = require('blob-util');

export const getMsGraphAccessToken = async () => {
  //console.debug('getMsGraphAccessToken');
  let accessToken = null;
  const account = msalInstance.getActiveAccount();
  //console.debug('getMsGraphAccessToken - getActiveAccount - account', account);
  if (!account) {
    return "getMsGraphAccessToken - No active account! Verify a user has been signed in and setActiveAccount has been called.";
  }
  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account
  });
  //console.debug('getMsGraphAccessToken - acquireTokenSilent - response', response);
  accessToken = response.accessToken;
  //console.debug('getMsGraphAccessToken - acquireTokenSilent - accessToken', accessToken);
  const auth = {
    localAccountId: account.localAccountId,
    accessToken
  }
  return auth
}

export async function callMsGraph() {
  //console.debug('callMsGraph');
  const account = msalInstance.getActiveAccount();
  //console.debug('callMsGraph - account', account);
  if (!account) {
    throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account
  });
  //console.debug('callMsGraph - response', response);

  const headers = new Headers();
  const bearer = `Bearer ${response.accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers
  };
  //console.debug('callMsGraph - options', options);

  let graphProfileResponse;

  return fetch(graphConfig.graphMeEndpoint, options)
    .then(response => {
      //console.debug('callMsGraph - api response', response);
      return (
        response.json()
      )
    })
    .then(response => {
      graphProfileResponse = response;

      headers.append('Content-Type', 'image/jpeg');

      const graphMePhotoOptions = {
        encoding: null,
        method: 'GET',
        headers: headers
      };

      return (
        fetch(graphConfig.graphMePhotoEndpoint, graphMePhotoOptions)
      )
    })
    .then(async response => {
      //console.debug('callMsGraph - graphMePhotoEndpoint - api response', response);
      const blob = await response.blob();
      // eslint-disable-next-line
      const blobUrl = window.URL.createObjectURL(blob);
      //console.debug('blobUrl', blobUrl);

      // base64 encode for easier storage
      await blobUtil.blobToBase64String(blob).then(function (base64String) {
        graphProfileResponse.photo = 'data:image/jpeg;base64,'+base64String;
      });
      return (
        graphProfileResponse
      )
    })
    .then(graphProfileResponse => {
      //console.debug('callMsGraph - graphProfileResponse with photo', graphProfileResponse);
      return (graphProfileResponse)
    })
    .catch(error => {
      console.log(error);
      return (
        error
      )
    });
}