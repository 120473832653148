// Public - Marketing - Homepage
// ----------------------
import React from 'react'
// import { useSelector, useDispatch } from 'react-redux'
// import { selectPublic, counter_increment, counter_decrement } from '../publicSlice'
// // routing
// import { Link, } from "react-router-dom";
// import {
//   Button,
// } from 'semantic-ui-react'
// styles
import styles from './homepage.module.scss';
// images
//import logo from '../../../logo.svg';


export default function Homepage() {
  
  return (
    <div className={styles.homepageView}>
      <header className="App-header">
      </header>
    </div>
  )
}