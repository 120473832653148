import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import counterReducer from '../features/counter/counterSlice'
// public
import { publicReducer } from '../features/public/publicSlice';
// auth/msal
import { authMsalReducer } from '../features/auth/msal/authSlice';
// campaign monitor
import { campaignMonitorReducer } from '../features/campaign-monitor/campaignMonitorSlice';

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    counter: counterReducer,
    public: publicReducer,
    authMsal: authMsalReducer,
    campaignMonitor: campaignMonitorReducer,
  },
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)
